@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&family=Lora&display=swap');

@font-face {
  font-family: 'Maria';
  src: local('Maria'), url(./Maria.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
html {
  -webkit-transition: all 5s ease-in-out !important;
    -moz-transition: all 5s ease-in-out !important;
    -o-transition: all 5s ease-in-out !important;
    transition: all 5s ease-in-out !important;
    background: linear-gradient(white, hsla(11, 100%, 57%, 0.08)) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

body {
  background-image: linear-gradient(rgba(0,0,0,0), hsla(11, 100%, 57%, 0.5));
  color: #3531FF;
  line-height: 1.65;
  min-height: 100vh;
}

a:hover{
  color: #FF4B22;
  transition: all 0.2s ease-in-out !important;

}

a {
  color: #3531FF;
  font-family: 'Maria';
  text-decoration: none;
}

h1, h2, h3 {
  font-family: 'Maria';
  font-weight: 300;
  display: inline-block;
  width: fit-content;
}
.name {
    margin-left: 32px;
    line-height: 1;
    padding-right: 32px;
    font-size: 48px;
}

.name-header {
  margin-left: 32px;
}

.name-header h1 {
  margin-left: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.name-header p {
  margin-top: 6px;
  margin-bottom: 6px;
}




.App {
  text-align: left;
  font-family: 'Lora', serif;
  font-weight: 400;
  width: 50%;
  max-width: 1000px;
  border-left: 1.5px solid white;
  border-right: 1.5px solid white;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: top;
  background: -webkit-linear-gradient(#3531FF, #045C6D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Profile-section {
  padding: 84px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex-grow: 1;
}

.section-header {
  padding-bottom: 42px;
}

.Profile-section-wrapper {
  width: 100%;
  margin-left: -32px;
  border-top: 1.5px solid white;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.profile-icons {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.social-icon {
  margin: 12px;
  width: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FF4B22;
}

.social-icon:hover {
  color: #3531FF;
}

.pfp {
  width: 75px;
  height: 75px;
  background-color: #FF4B22;
  border-radius: 15px;
  border: 0px;
  display: inline-block;
  background-image: url('./pfp.png');
  background-size:cover;
   -webkit-box-shadow: 0px 5px 18px -6px rgba(0,0,0,0.45); 
  box-shadow: 0px 5px 18px -6px rgba(0,0,0,0.45);
}

.pfp:hover {
  box-shadow: 0px 0px 12px hsla(11,100%,57%,0.5);
  transition: all 0.2s ease-in-out !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Bio {
  display: block;
  font-size: 18px;
}

.Projects {
  display: flex;
  flex-direction: column;
  flex-align: left;
  justify-content: left;
}

.project-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-align: left;
  justify-content: left;
}

.project-item {
  padding: 64px 32px;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: left;
}

.project-list-title {
  margin: 0;
}

.project-image {
  width: 200px;
  background-color: #ccc;
  height: 200px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 5px 18px -6px rgba(0,0,0,0.45); 
  box-shadow: 0px 5px 18px -6px rgba(0,0,0,0.45);
}
.project-description {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-align: left;
  justify-content: left;
  padding-left: 32px;
  font-family: 'Inter';
}

.project-name {
  display: flex;
  flex-direction: row;
  flex-align: center;
  justify-content: right;
  font-family: 'Maria';
}

.project-title {
  font-family: 'Maria';
  font-size: 24px;
  color: #3531FF;
  flex-grow: 1;
  line-height: 1;
}

.project-date {
  min-width: 140px;
  text-align: right;
  font-family: 'Inter';
  font-weight: 400;
  color: #3531FF;
  font-size: 14px;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 16px;
}

.project-subheader {
  font-family: 'Inter';
  color: #3531FF;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
}

.project-skills {
  margin-top: 16px;
  margin-bottom: 16px;
}

.project-description-text {
  font-size: 14px;
}

.project-subheader a {
  font-family: 'Inter';
  color: #3531FF;
  font-size: 14px;
}

.project-description-text {
  color: #3531FF;
}

.project-description-text a {
  color: #3531FF;
}

.project-description-text a:hover {
  color: #FF4B22;  
}

.project-subheader a:hover {
  color: #FF4B22;  
}


.Principles {

  display: block;
}

.Writing {

  display: block;
}


.Writing p {
  margin-bottom: 32px;
}


.Interests {
  display: block;
}

.art-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
}
.art {
  width: calc(50% - 16px);
  border-radius: 15px;
  -webkit-box-shadow: 0px 5px 18px -6px rgba(0,0,0,0.45); 
  box-shadow: 0px 5px 18px -6px rgba(0,0,0,0.45);
}










@media only screen and (max-width: 1200px) {
  .App {
    width: 60%;
  }
}

@media only screen and (max-width: 1100px) {
  .App {
    width: 70%;
  }
}

@media only screen and (max-width: 1000px) {
  .App {
    width: 80%;
  }
}

@media only screen and (max-width: 900px) {
  .App {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .App {
    width: 90%;
  }
  .Profile-section {
    padding: 48px 32px;
  }

  .project-item {
    flex-direction: column;
  }

  .project-image {
    width: 100%;
    height: auto;
    margin-bottom: 32px;
  }

  .project-description {
    padding-left: 0px;
  }

  .Profile-section-wrapper {
  }
  .art-wrapper {
    flex-direction: column;
    gap: 32px;
  }
  .art {
    width: 100%;
    border-radius: 15px;
  }

}


.Principles h2 {
  margin-bottom: 0px ;
}

@media only screen and (max-width: 500px) {
  .Profile-section {
    padding: 48px 32px;
    flex-wrap: wrap;

  }

  .profile-icons {

  justify-content: center;
  }
  .Profile-section {
    justify-content: center;
  }
  .name {
    text-align: center;
    
  }

  .name-header {
    margin: 0;
  }

  .name-header h1 {
    margin-top: 32px;
    margin-bottom: 32px;
    text-align: center;
    padding: 0;
    width: 100%;
  }

  .name-header p {
    text-align: center;
    padding: 0;
  }
}

@media only screen and (max-width: 440px) {
  .Profile-section {
    justify-content: center;
  }
  .name {
    text-align: center;

  }
}





@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
